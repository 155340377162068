<template>
  <UiTourDriver
    ref="driver"
    :tour="TOURS_IDS.PROPERTIES_OFFERS"
    :highlight
    :type="TOURS_TYPES.NEW_FEATURE"
    :steps
    @destroyed="useMittEvent('tour:propertiesOffers')"
  />
</template>

<script setup lang="ts">
import type { DriveStep } from 'driver.js'
import type { UiTourDriver } from '#build/components'
import { TOURS_IDS, TOURS_TYPES } from '~/constants'
import { useUiStore } from '~/store/ui'

const uiStore = useUiStore()

const router = useRouter()

const driver = ref<InstanceType<typeof UiTourDriver> | null>(null)

onMounted(() => {
  useMittListen('tour:propertiesOffers', () => {
    if (driver.value?.driverObj) {
      driver.value.startTour()
    }
  })
})

const highlight = ref<DriveStep>({
  element: '#share-with-client',
  popover: {
    title: 'Create property brochure',
    description: `Now you can easily create property brochure and send it to client within CRM!`,
    nextBtnText: 'Learn more',
    onNextClick: () => {
      const shareButton = document.querySelector<HTMLElement>('#share-with-client')
      if (!shareButton) return
      shareButton.click()
      setTimeout(() => {
        if (driver.value?.driverObj) {
          driver.value.driverObj.drive()
        }
      }, 300)
    },
  },
})

const steps = computed<DriveStep[]>(() => [
  {
    element: '#recipient-and-description',
    popover: {
      title: '1/6 Select lead',
      description: `Choose recipient from your leads and adjust description to make brochure individual and engaging.`,
      align: 'start',
      side: 'left',
      onNextClick: async () => {
        await nextTick()
        if (driver.value?.driverObj) {
          driver.value.driverObj.moveNext()
        }
      },
    },
  },
  {
    element: '#popup_primary',
    popover: {
      title: '2/6 Create brochure',
      description: `Create brochure and find it ready on the lead page to share with the lead.`,
      side: 'top',
      align: 'end',
      onNextClick: async () => {
        const closeButton = document.querySelector<HTMLElement>('#popup_secondary')
        if (!closeButton) return
        closeButton.click()
        await navigateTo('/properties/all')
        uiStore.showSnackBanner(
          `You have created offer for lead. 
        Copy link for brochure or open it through lead page.`,
          'success',
          async () =>
            await navigateTo({
              name: 'leads-id',
              params: { id: '1' },
              query: { tour: 'propertiesOffers' },
              hash: '#offers',
            }),
          'Open in lead page',
          () => {},
          'Copy link'
        )
        await nextTick()
        if (driver.value?.driverObj) {
          driver.value.driverObj.moveNext()
        }
      },
    },
  },
  {
    element: '#snack-banner',
    popover: {
      title: '3/6 Created offer',
      description: `You can copy link to the brochure right away or later in the lead page.`,
      side: 'top',
      align: 'center',
      onPrevClick: async () => {
        await router.back()
        setTimeout(() => {
          const shareButton = document.querySelector<HTMLElement>('#share-with-client')
          if (!shareButton) return
          shareButton.click()
          setTimeout(() => {
            if (driver.value?.driverObj) {
              driver.value.driverObj.movePrevious()
            }
          }, 400)
        }, 600)
      },
      onNextClick: async () => {
        const actionButton = document.querySelector<HTMLElement>('#action_button')
        if (!actionButton) return
        actionButton.click()
        await nextTick()
        setTimeout(() => {
          if (driver.value?.driverObj) {
            driver.value.driverObj.moveNext()
          }
        }, 700)
      },
    },
  },
  {
    element: '#activity-element',
    popover: {
      title: '4/6 Offers on lead page',
      description: `You can easily find all lead's offers on  the lead page.`,
      side: 'left',
      align: 'start',
      onPrevClick: async () => {
        await router.back()
        setTimeout(() => {
          uiStore.showSnackBanner(
            `You have created offer for lead. 
        Copy link for brochure or open it through lead page.`,
            'success',
            async () =>
              await navigateTo({
                name: 'leads-id',
                params: { id: '1' },
                query: { tour: 'propertiesOffers' },
                hash: '#offers',
              }),
            'Open in lead page',
            () => {},
            'Copy link'
          )
          setTimeout(() => {
            if (driver.value?.driverObj) {
              driver.value.driverObj.movePrevious()
            }
          })
        }, 600)
      },
      onNextClick: () => {
        if (driver.value?.driverObj) {
          driver.value.driverObj.moveNext()
        }
      },
    },
  },
  {
    element: '#link',
    popover: {
      title: '5/6 Copy link to brochure',
      description: `Just click on the icon to copy link to the brochure and feel free to share it with the lead.`,
      side: 'left',
      align: 'start',
      onNextClick: () => {
        if (driver.value?.driverObj) {
          driver.value.driverObj.moveNext()
        }
      },
    },
  },
  {
    element: '#statistics',
    popover: {
      title: '6/6 Opening Statistics',
      description: `View opening statistic for understanding lead engagement and planning future communication with the lead.`,
      side: 'left',
      align: 'start',
      onNextClick: async () => {
        if (driver.value?.driverObj) {
          driver.value.driverObj.destroy()
        }
        await navigateTo('/properties/all')
      },
    },
  },
])

onUnmounted(() => {
  useMittRemove('tour:propertiesOffers')
})
</script>

<style scoped lang="scss">
:deep() {
  .driver-popover-next-btn {
    width: 100% !important;
  }
}
</style>
